var normalHttpStatusCodes = [
  "301",
  "302",
  "303",
  "304",
  "305",
  "307",
  "400",
  "401",
  "403",
  "404",
  "405",
  "406",
  "407",
  "408",
  "409",
  "500",
  "501",
  "502",
  "503",
  "504",
  "505",
];
export default function myAjax(options) {
  var xhr = window.XMLHttpRequest
    ? new XMLHttpRequest()
    : new ActiveXObject("Microsoft.XMLHTTP");
  var method = options.type.toUpperCase(); // 确保 method 是大写
  var url = options.url;
  var contentType =
    options.contentType || "application/x-www-form-urlencoded;charset=UTF-8";
  var data = options.data;
  var timeout = options.timeout || 8000; // 默认超时时间为8秒
  var successCallback = options.success;
  var errorCallback = options.error;
  var uid = data.uid;
  xhr.timeout = timeout; // 设置超时时间

  xhr.onreadystatechange = function () {
    if (normalHttpStatusCodes.includes(xhr.status)) {
      xhr = null;
      throw new Error(`${url}-${uid} \n ${xhr.status}`);
    }
    if (!xhr || xhr.readyState !== 4) {
      return;
    }

    // The request errored out and we didn't get a response, this will be
    // handled by onerror instead
    // With one exception: request that using file: protocol, most browsers
    // will return status as 0 even though it's a successful request
    if (
      xhr.status === 0 &&
      !(xhr.responseURL && xhr.responseURL.indexOf("file:") === 0)
    ) {
      return;
    }
    // readystate handler is calling before onerror or ontimeout handlers,
    // so we should call onloadend on the next 'tick'
    setTimeout(() => {
      successCallback(
        xhr.responseText ? JSON.parse(xhr.responseText) : xhr.responseText
      );
    });
  };
  xhr.onabort = function handleAbort() {
    if (!xhr) {
      return;
    }
    alert("${url} Request aborted \n ${uid}");
    xhr = null;
  };
  xhr.onerror = function () {
    if (!xhr) return;
    errorCallback("Network Error");
    // 抛出网络错误
    setTimeout(() => {
      throw new Error(`${url} Network Error \n ${uid}`);
    }, 200);
    xhr = null;
  };
  xhr.ontimeout = function () {
    if (!xhr) return;
    errorCallback(`timeout of ${timeout}ms exceeded`);
    xhr = null;
    // 抛出超时错误
    setTimeout(() => {
      throw new Error(`${url} timeout of ${timeout}ms exceeded \n ${uid}`);
    }, 200);
  };

  xhr.open(method, url, true);
  xhr.setRequestHeader("Content-Type", contentType);
  if (data) {
    if (contentType === "application/x-www-form-urlencoded;charset=UTF-8") {
      // 将对象转换为 application/x-www-form-urlencoded 格式
      data = objectToUrlEncoded(data);
      xhr.send(data);
    } else {
      xhr.send(JSON.stringify(data));
    }
  }
}

function objectToUrlEncoded(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}
