import Vue from "vue";
import wewoo from "@wewoo/error";
import myAjax from "./myAjax";
if (location.origin === "http://pay.wewoo.testing") {
  Vue.use(wewoo, {
    dsn: location.origin + "/wewoo-log-center/monitor/reportErrorData",
    sysCode: "ERP",
    repeatCodeError: false,
    useImgUpload: false,
    userId: "pay_user",
    handleHttpStatus() {
      return true;
    },
  });
}

Vue.config.productionTip = true;

var flag = true;
var link = null;
var total;
var current;
var degStep;
var intCurrent;
var remain;
var saveTime = 0;
var ajaxUrl = getConfigRequest(); // 测试地址
var s = null; //定时器
var $ = window.$;
var JSEncrypt = window.JSEncrypt;
var view = {
  // 初始化加载获取倒计时
  getTimeOut: function () {
    $.ajax({
      type: "POST",
      url: ajaxUrl + "/payservice/paymentLink/getCurrentLeftTimeStamp",
      data: {
        PaymentLinkId: link,
      },
      dataType: "json",
      success: function (res) {
        total = 172800;
        current = 172800 - Math.floor(res.data / 1000);
        degStep = 360 / total;
        intCurrent = 0;
        remain = total - current - 1;
        if (current < total / 2) {
          intCurrent = current;
          $(".pie1").css(
            "-o-transform",
            "rotate(" + intCurrent * degStep + "deg)"
          );
          $(".pie1").css(
            "-moz-transform",
            "rotate(" + intCurrent * degStep + "deg)"
          );
          $(".pie1").css(
            "-webkit-transform",
            "rotate(" + intCurrent * degStep + "deg)"
          );
        } else {
          intCurrent = current - total / 2;
          $(".pie1").css("-o-transform", "rotate(" + 180 + "deg)");
          $(".pie1").css("-moz-transform", "rotate(" + 180 + "deg)");
          $(".pie1").css("-webkit-transform", "rotate(" + 180 + "deg)");
          $(".pie2").css(
            "-o-transform",
            "rotate(" + intCurrent * degStep + "deg)"
          );
          $(".pie2").css(
            "-moz-transform",
            "rotate(" + intCurrent * degStep + "deg)"
          );
          $(".pie2").css(
            "-webkit-transform",
            "rotate(" + intCurrent * degStep + "deg)"
          );
        }
        if (remain > 0) {
          s = setInterval(showTime, 1000);
        } else {
          clearInterval(s);
        }
      },
      error: function () {
        alert("The request failed!");
      },
    });
  },
  // 根据邮编获取 国家, 州/省 数据
  getInfo: function () {
    $.ajax({
      type: "POST",
      url: ajaxUrl + "/payservice/zipcode/getInfo",
      data: {
        zipcode: $("input#postcode").val(),
      },
      dataType: "json",
      success: function (res) {
        $("input#country").val(res.data.country);
        $("input#province").val(res.data.state);
      },
      error: function () {
        alert("The request failed!");
      },
    });
  },
  // 判断当前链接是否失效
  isCheckLink: function () {
    myAjax({
      type: "POST",
      url: ajaxUrl + "/payservice/paymentLink/check",
      data: {
        PaymentLinkId: link,
      },
      dataType: "json",
      success: function (res) {
        if (res.data) {
          view.getTimeOut();
        } else {
          $(".pay-error").css("display", "block");
        }
      },
      error: function () {
        alert("The request failed!");
      },
    });
  },
  // 保存
  save: function () {
    flag = false;
    $(".loading").css("display", "block");
    var screenInfo = getScreenInfo();
    var params = {
      accountHolder: $("input#card").val(), //	账户持有人姓名
      address: $("input#Address1").val(), //	账单地址 1
      address2: $("input#Address2").val(), //	账单地址 2
      cardCode: $("input#code").val(), // CVV2/CID卡背面的代码值。如果代码不可读，则设置为-2；如果卡上没有代码，则设置为-9。
      cardExpiration: $("input#expiration").val().replaceAll("/", ""), // 	有效期（expired date）
      cardNumber: $("input#cardNumber").val(), //	卡号（card number），卡片正面的16位数字
      city: $("input#city").val(), //	城市
      companyName: $("input#companyName").val(), //	账单地址 公司
      contactName: $("input#contact").val(), //	账单地址 联系名称
      country: $("input#country").val(), //	国家
      link: getQueryVariable("link"), //	链接号
      orderCode: $("input#plNumber").val(), // 	订单号
      payAmount: $("input#amount").val(), // 应付金额
      serviceCharge: $("input#charge").val(), // 手续费
      totalAmount: $(".total-amount").text(), // 总金额
      stateOrProvince: $("input#province").val(), // 州/省
      telNumber: $("input#phone").val(), // 电话
      zipOrPostalCode: $("input#postcode").val(), // 邮编
      screenHeight: screenInfo.screenHeight, //屏幕高度
      screenWidth: screenInfo.screenWidth, //屏幕宽度
    };
    var encryptedData = view.encryptedData(encodeURI(JSON.stringify(params)));
    var len = $.base64.decode(encryptedData).length;

    if (len % 128 === 0 || saveTime > 2) {
      $.ajax({
        type: "POST",
        url: ajaxUrl + "/payservice/paymentLink/pay",
        contentType: "application/json;charset=utf-8;",
        data: JSON.stringify({
          paramKey: encryptedData,
          sourceKey: JSON.stringify(params),
        }),
        timeout: 8000,
        dataType: "json",
        success: function (res) {
          if (res.code == "000000") {
            // alert('Payment success')
            localStorage.setItem("payInfo", JSON.stringify(res.data));
            window.location.href = "./success.html";
          } else {
            alert(res.desc);
          }
          $(".form-submit").removeClass("dis");
          flag = true;
          $(".loading").css("display", "none");
        },
        error: function () {
          alert("The request failed!");
          $(".form-submit").removeClass("dis");
          flag = true;
          $(".loading").css("display", "none");
        },
      });
      saveTime = 0;
    } else {
      saveTime += 1;
      view.save();
    }
  },

  // 保存数据加密
  encryptedData(data) {
    let publicKey =
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCpxwveJpKJkdrY9nIciDUvS/X4ijbtJj1bHOlyMqH+dqkt+EwegUR3lv4YvCd6NzO9T4jDWRjV8HGwKqrdU1r2kMfLWdPcByq0XV4x8av3PrIxUpPPFv9cnP+Dyqmqk/+myVnxDsI44n/cvpXLn9REj4GaF9FzOLggdHSPYf7F9wIDAQAB";
    // 新建JSEncrypt对象
    let encryptor = new JSEncrypt();
    // 设置公钥
    encryptor.setPublicKey(publicKey);

    let paramKey = encryptor.encryptLong(data);
    return paramKey;
  },

  addEvents: function () {
    $("input#postcode").on("blur", function () {
      view.getInfo();
    });
    $(".city_options")
      .find("li")
      .on("click", function () {
        var code = $(this).text() == "US" ? 2 : 55;
        $.ajax({
          type: "POST",
          url: ajaxUrl + "/payservice/zipcode/findProvince",
          data: { parentId: code },
          dataType: "json",
          success: function (res) {
            var stateList = "";
            $(".state_list").empty();
            $("input#province").val("");
            res.data.map((item) => {
              stateList +=
                "<li data-code=" + item.nameEn + ">" + item.nameEn + "</li>";
            });
            $(".state_list").append(stateList);
          },
          error: function () {
            alert("The request failed!");
          },
        });
      });
    $(".state_list").on("click", "li", function (event) {
      event.stopPropagation();
      $(this).closest(".comm-select").removeClass("iserror");
      $("input#province").val($(this).attr("data-code"));
      $(".state_list").css("display", "none");
    });
  },
  init: function () {
    getUrlParams();
    view.addEvents();
  },
};
window.view = view;
// 初始化加载数据
view.init();

// 获取配置地址
function getConfigRequest() {
  var url = window.location.origin;

  if (url.indexOf("testing") > 0) {
    return "http://erp.wewoo.testing/payservice";
  } else if (url.indexOf("pre") > 0) {
    return "http://erp.wewoo.pre/pay";
  } else if (url.indexOf("beta") > 0) {
    return "https://beta.wewoerp.com/pay";
  } else if (url.indexOf("develop") > 0) {
    return "";
  } else if (url.indexOf("pay.eqstep.com/payrp") > 0) {
    return "https://www.wewoerp.com/pay";
  } else {
    return "http://erp.wewoo.testing/payservice";
  }
}

// 获取url参数并赋值
function getUrlParams() {
  let orderCode = getQueryVariable("orderCode");
  let payAmount = getQueryVariable("payAmount");
  let serviceCharge = getQueryVariable("serviceCharge");
  let totalAmount = getQueryVariable("totalAmount");

  $("input#plNumber").val(orderCode);
  $("input#amount").val(payAmount);
  $("input#charge").val(serviceCharge);
  $(".total-amount").text(totalAmount);
  link = getQueryVariable("link");
  view.isCheckLink();
}

function getQueryVariable(variable) {
  var url = window.location.href;
  var query = $.base64.decode(url.split("param=")[1]);
  var vars = query.substring(query.indexOf("?") + 1).split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

// 倒计时方法
function start1(num) {
  var currentDeg1 = num * degStep;
  $(".pie1").css("-o-transform", "rotate(" + currentDeg1 + "deg)");
  $(".pie1").css("-moz-transform", "rotate(" + currentDeg1 + "deg)");
  $(".pie1").css("-webkit-transform", "rotate(" + currentDeg1 + "deg)");
}
function start2(num) {
  var currentDeg2 = num * degStep;
  $(".pie2").css("-o-transform", "rotate(" + currentDeg2 + "deg)");
  $(".pie2").css("-moz-transform", "rotate(" + currentDeg2 + "deg)");
  $(".pie2").css("-webkit-transform", "rotate(" + currentDeg2 + "deg)");
}
function showTime() {
  remain = remain - 1;
  if (remain < 1) {
    clearInterval(s);
  } else {
    var HH = Math.floor(remain / 3600);
    var MM = Math.floor((remain - 3600 * HH) / 60);
    var SS = (remain - 3600 * HH) % 60;
  }
  var h = HH;
  var m = MM;
  var s = SS;
  h = HH < 10 ? "0" + HH : HH;
  if (MM < 10) {
    m = "0" + MM;
  }
  if (SS < 10) {
    s = "0" + SS;
  }
  if (remain < total / 2) {
    start2(total / 2 - remain);
  } else {
    start1(total - remain);
  }
  if (remain > 0) {
    $(".time span").html(h + ":" + m + ":" + s);
  } else {
    $(".hold").css("display", "none");
    $(".time span").html("00:00:00");
    $(".pay-error").css("display", "block");
  }
}

// 输入框获得焦点时
$("input").focus(function (event) {
  //label动态上升，升至顶部
  $(this).parent().addClass("isfocus");
  $(this).siblings("label").stop().animate({ bottom: "25px" }, 500);
});

// 输入框失去焦点时
$("input").blur(function (event) {
  $(this).parent().removeClass("isfocus");
  if (event.target.value) {
    $(this).parent().removeClass("iserror");
    if ($(this).parent().next().hasClass("ml-20")) {
      $(this).parent().next().css("margin-bottom", "16px");
    }
    return false;
  } else {
    $(this).parent().addClass("iserror");
    if ($(this).parent().next().hasClass("ml-20")) {
      $(this).parent().next().css("margin-bottom", "42px");
    }
  }
  if ($(this).parent().hasClass("comm-select")) {
  } else {
    //label动态下降，恢复原位
    $(this).siblings("label").stop().animate({ bottom: "13px" }, 500);
  }
});

$("#cardNumber").blur(function (event) {
  if (event.target.value == "amex") {
    $(this)
      .siblings(".select-country")
      .find(".card-select img")
      .attr("src", "img/amex.png");
  } else if (event.target.value == "discover") {
    $(this)
      .siblings(".select-country")
      .find(".card-select img")
      .attr("src", "img/discover.png");
  } else if (event.target.value == "moster") {
    $(this)
      .siblings(".select-country")
      .find(".card-select img")
      .attr("src", "img/moster.png");
  } else if (event.target.value == "visa") {
    $(this)
      .siblings(".select-country")
      .find(".card-select img")
      .attr("src", "img/visa.png");
  }
});

$("#cardNumber").keyup(function () {
  var value = $(this).val(); //输入的时候获取输入框的值
  $("#cardNumberhide").val(value.replace(/./g, "*"));
});

$(".card-select").click(function () {
  $(this).siblings(".options").css("display", "block");
});
$(".options>li").click(function () {
  $(this)
    .parent()
    .siblings(".card-select")
    .find("img")
    .attr("src", "img/" + $(this).attr("data-code") + ".png");
  $(".options").css("display", "none");
});

$(".comm-select").click(function (event) {
  event.stopPropagation();
  if ($(this).find(".comm_options").css("display") === "block") {
    $(this).find(".comm_options").css("display", "none");
  } else {
    $(this).find(".comm_options").css("display", "block");
  }
});
$(".comm-select .comm_options>li").on("click", function (event) {
  event.stopPropagation();
  $(this).parent().parent().removeClass("iserror");
  if ($(this).parent().parent().next().hasClass("ml-20")) {
    $(this).parent().parent().next().css("margin-bottom", "16px");
  }
  $(this).parent().siblings("input").val($(this).attr("data-code"));
  $(this).parent().css("display", "none");
});

$(".form-submit").click(function () {
  if (flag) {
    if (!$("input#cardNumber").val()) {
      alert("Card Number is required");
      return false;
    } else if (!$("input#companyName").val()) {
      alert("Company Name is required");
      return false;
    } else if (!$("input#postcode").val()) {
      alert("Zip/Postal Code is required");
      return false;
    } else if (!$("input#city").val()) {
      alert("City is required");
      return false;
    } else if (!$("input#Address1").val()) {
      alert("Address is required");
      return false;
    } else if (!$("input#contact").val()) {
      alert("Contact Name is required");
      return false;
    } else if (!$("input#phone").val()) {
      alert("Tel Number is required");
      return false;
    } else {
      $(".form-submit").addClass("dis");
      view.save();
    }
  }
});
$(".pay-error .close-icon").click(function () {
  $(".pay-error").css("display", "none");
});

$(".pay-timeout .close-icon").click(function () {
  $(".pay-timeout").css("display", "none");
});

// 截止日期
$("input#expiration").on("keyup", function (e) {
  var val = $(this).val();
  // 按下回退键或删除键终止运行
  if (e.key === "Backspace" || e.key === "Delete") return false;
  // 月份禁止输入00
  if (val === "00") {
    val = "0";
    $(this).val(val);
  }
  // 禁止第一位或者第二位输入/
  if (
    val.charAt(0) === "/" ||
    (val.charAt(1) === "/" && val.charAt(0) != "1")
  ) {
    val = val.replaceAll("/", "");
    $(this).val(val);
  }
  // 月份第一位大于1,格式处理0+${月份}+'/'
  if (val.charAt(0) > 1 && val.length === 1) {
    val = "0" + val + "/";
    $(this).val(val);
  }
  // 月份不能大于12
  if (val.length === 2) {
    if (val > 12) {
      val = "0" + val.substring(0, 1) + "/" + val.substring(1, val.length);
    } else if (val.indexOf("/") === -1) {
      val = val + "/";
    } else if (val === "1/") {
      val = "01/";
    }
    $(this).val(val);
  }
  // 输入三位以上没有/手动添加/
  if (val.length > 2 && val.indexOf("/") === -1) {
    val = val.substring(0, 2) + "/" + val.substring(2, val.length);
    $(this).val(val);
  }
});

// 校验截至日期是否大于当前日期
$("input#expiration").on("blur", function () {
  var val = $(this).val();

  if (val.length === 5) {
    var expDate = val.split("/");
    var date = new Date();
    var realDate = [
      date.getMonth() + 1,
      Number(String(date.getFullYear()).substring(2, 4)),
    ];

    if (
      (realDate[0] > Number(expDate[0]) &&
        Number(expDate[1]) === realDate[1]) ||
      realDate[1] > Number(expDate[1])
    ) {
      $(".date-tips").removeClass("hide");
      $(this).css("color", "#E02020");
      $(this).closest(".item").css("margin-bottom", "42px");
    } else {
      $(".date-tips").addClass("hide");
      $(this).css("color", "#333");
      $(this).closest(".item").css("margin-bottom", "16px");
    }
  } else {
    $(".date-tips").addClass("hide");
    $(this).css("color", "#333");
    $(this).closest(".item").css("margin-bottom", "16px");
  }
});

// logo跳转
function goTo() {
  window.open("https://www.geotrust.com/", "_blank");
}

// 获取屏幕宽度和高度
function getScreenInfo() {
  return {
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
  };
}
